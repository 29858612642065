

.table-result{
  tr{
    background: #ffffff !important;
  }

  th{
    background: #ffffff !important;

    border-bottom: 1px solid $divider !important;
    &:before{
     width: 0px !important;
    }
  }
}

