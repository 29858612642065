.AuthWrapper {
    background-color: rgb(255, 255, 255);
    width: 100vw;
    transition: all 0.6s ease-in-out;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    overflow: auto;

  .FormPage{
    box-shadow: 0px 4px 15px rgba(3, 17, 51, 0.12);
    border-radius: 22px;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 40px 45px;
    flex-direction: column;
    gap: 16px;

    h2{

    }
    .FormItem{
      width:300px;
      margin: 0 !important;
      align-items: center;
      display: flex;
      font-size: 24px;
      justify-content: center;
      padding:0 !important;
      :global{
        .ant-form-item-explain-error{
          font-size: 12px !important;
        }
      }

    }
    .FormItemB{
      margin-bottom: 0px !important;
    }

    .Input{
      width: 300px;
      height: 40px;
     margin: 0;
      border-radius: 10px;
      align-items: center;;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      .ant-form-item-explain {
        margin-left: 10px;

      }
      .ant-form-item-explain-error{
        width: 60% !important;
      }

  }
    .Auth {
        box-shadow: 0px 4px 15px rgba(3, 17, 51, 0.12);
        border-radius: 22px;
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        padding: 40px 44px;
        flex-direction: column;
        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: 35px;
            line-height: 42px;
        }
        }
    }
}

//
.RadioGroup{
  display: flex;
  gap: 30px;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #060606 !important;
}



 


