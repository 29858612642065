$primary-1: #e6f7ff;
$primary-2: #bae7ff;
$primary-3: #91d5ff;
$primary-4: #69c0ff;
$primary-5: #40a9ff;
$primary-6: #1890ff;
$primary-7: #146dd9;
$primary-8: #2456c8;
$primary-9: #194198;
$primary-10: #142766;

$natural-white: #ffffff;

$natural-gray-25: #fafafa;
$natural-gray-50: #f5f5f5;
$natural-gray-100: #f0f0f0;
$natural-gray-200: #d9d9d9;
$natural-gray-300: #bfbfbf;
$natural-gray-350: #b5b5b5;
$natural-gray-400: #8c8c8c;
$natural-gray-420: #858585;
$natural-gray-500: #595959;
$natural-gray-600: #434343;
$natural-gray-700: #262626;
$natural-gray-800: #1f1f1f;
$natural-gray-900: #141414;

$natural-black: #060606;

$blue-1: #f0f5ff;
$blue-2: #d6e4ff;
$blue-3: #adc6ff;
$blue-4: #85a5ff;
$blue-5: #597ef7;
$blue-6: #2f54eb;
$blue-7: #1d39c4;
$blue-8: #10239e;
$blue-9: #061178;
$blue-10: #030852;

$character-primary: #242424;
$character-secondary: #858585;
$character-placeholder: #b5b5b5;
$character-primary-inverse: #ffffff;
$character-title: #1a1a1a;
$character-success: #00922f;
$character-warning: #ffbe00;
$character-danger: #dd262c;

$conditional-bg: #e7edf6;
$conditional-sitebar:#0B1328;
$conditional-sb_hover: #101A32;
$conditional-sb_disable: #8c8c8c;
$conditional-sb_active1: #ffffff;
$conditional-sb_active2: #2359bb;
$conditional-divider: #f0f0f0;
$conditional-bg_modal: #060606;
$conditional-fold: #101C3D;
$conditional-down: #070C18;

$icon-link: #1890ff;
$icon-default: #1d39c4;

$orange-1: #fff7e6;
$orange-2: #ffe7ba;
$orange-3: #ffd591;
$orange-4: #ffc069;
$orange-5: #ffa940;
$orange-6: #fa8c16;
$orange-7: #d46b08;
$orange-8: #ad4e00;
$orange-9: #873800;
$orange-10: #612500;

//Neutral
$white: #FFFFFF;
$gray-25:#fafafa;
$gray-50:#f5f5f5;
$gray-100: #DFDDDD ;
$gray-200: #BFBFBF;
$gray-300: #919191;
$gray-400: #757575;
$gray-500: #595959;
$gray-600: #434343;
$gray-700: #262626;
$gray-800: #1f1f1f;
$gray-900: #141414;
$black:#060606;

//Conditional
$SB_hover: #161D2F;
$SB_disable:$gray-400;
$SB_active1: white;
$SB_active2: #2359BB;
$SiteBar: #0A1123;
$BG: #E7EDF6;
$divider: $gray-100;

//Primary

$prim-1: #E6F7FF;;
$prim-2: #BAE7FF;
$prim-3: #91D5FF;
$prim-4:#69C0FF;
$prim-5: #40A9FF;
$prim-6:#1890FF;
$prim-7:#146DD9;
$prim-8: #2456C8;
$prim-9: #194198;
$prim-10: #142766;
$prim-11: #19191D;

//Blue

$blue-1: #F0F5FF;
$blue-2: #D6E4FF;
$blue-3: #ADC6FF;
$blue-4: #85A5FF;
$blue-5: #597EF7;
$blue-6: #2F54EB;
$blue-7: #1D39C4;
$blue-8: #10239E;
$blue-9: #061178;
$blue-10: #030852;


//Icon
$IconLink:$prim-6;
$IconDefault:$blue-7;

//Text

$TextTitle:#1A1A1A  ;
$TextInput:#B5B5B5 ;
$TextPrimary:#242424 ;
$TextPrimaryIn:#FFFFFF ;
$TextSecondary:#858585  ;
$TextDanger:#EC292F ;
$TextWarning:#FFBE00 ;
$TextSuccess:#00922F ;


$shadow10: 0px 77px 118px rgba(3, 17, 51, 0.04), 0px 15.4px 19.175px rgba(3, 17, 51, 0.02);
$shadow20:0px 4px 17px rgba(3, 17, 51, 0.04);
$shadow30:0px 29px 121px rgba(3, 17, 51, 0.03), 0px 5.8px 19.6625px rgba(3, 17, 51, 0.06);


@mixin btn-bolt(){
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: $white;
}

@mixin h2(){
  font-weight: 400!important;
  font-size: 35px !important;
  line-height: 42px !important;
  color: $black !important;
}
//other
$grid-step:4px;
$container-width: 90%;
$duration: 0.15s;
