.content{
  max-width: 80vw;
  border-radius: 10px;
  background: #FFFFFF !important;
  position: relative;
  margin-left:40px ;
  padding: 20px 6px 20px 0px;
  min-height: 380px;
  max-height: calc(80vw + 20px);

  .content-header{
    display: flex;
    gap: 16px;
    margin-left: 40px;
  }
}

.content-white{
  background: $white;
  border-radius: 10px;
  padding:20px 32px;
  .ant-input{
    border-radius: 10px;
    padding: 12px 16px !important;
  }
}
