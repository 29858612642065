
.scroll {
  height: 65vh;
  .simplebar-content-wrapper{
    padding: 0px 40px 0px 40px;
  }
  &:before {
    border-radius: 0px !important;

  }
  .simplebar-scrollbar:before {
    position: absolute;
    content: '';
    background: $divider !important;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  .simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }
}
