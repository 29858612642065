
.end_menu{
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  max-width: 593px;
  min-width: 560px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 36px;
  margin: 0 auto;
}
