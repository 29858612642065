
.modal-window{
display: flex;
 .modal-title{
   padding: 36px 20px;
   display: flex;
   gap: 20px;
   flex-direction:column;
   align-items: center;
   .title-text{

     font-weight: 500;
     font-size: 18px;
     line-height: 150%;
     color:$black;
     margin-bottom: 0px;
   }

 }
  .modal-btn-wrapper{
    display: flex;
    justify-content: center;
    gap: 12px;
  }
  padding: 24px;
    .ant-modal-header{
      border-radius: 16px !important;
    }
    .ant-modal-content{
      border-radius: 16px !important;
    }
  .ant-modal-body{
    text-align: center;
    font-size: 16px;
  }

  }

.modal-chart{
min-width: 40vw;
}
.modal-footer{
  //padding: 24px;
}
.modal-input{
  margin-bottom: 8px !important;

}
