@import './variables';
@import './libs/normalize';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import './components/all';

body {

}
body > div:nth-child(3){
  position: fixed !important;
}

.containere {
  width: calc(100vw - 100px);
  height: 100%;
  background-color: #fff;
  margin: 50px auto;
  border-radius: 10px;
  max-width: 1400px;

  @media (max-width: 645px) {
    width: calc(100vw - 30px);
    margin-top: 15px;
  }
}
.ant-notification {
margin-top: 40px !important;
}