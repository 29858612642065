@import './button';
@import './modal';
@import './menu';
@import "breadcrumb";
@import "./table";
@import "./header";
@import "./typography";
@import "./scrollbar";
@import "./containerContent";
@import "./testTest";
@import "./notFound";



@font-face {
  font-family: 'Inter Semi Bold',sans-serif !important;
}
#root{
  font-family: 'Inter Semi Bold',sans-serif !important;
  font-size: 30px !important;
  background: $BG;
}

html body{
  font-family: 'Inter Semi Bold',sans-serif !important;
  background: $BG;
}

.icon-warning{

}



