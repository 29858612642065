@mixin desktop {
  @media (min-width: (1025px)) {
    @content;
  }
}


@mixin middledesk {
  @media (max-width: (1440px)) {
    @content;
  }
}
@mixin middledeskM {
  @media (max-width: (1640px)) {
    @content;
  }
}

@mixin minidesk {
  @media (max-width: (1200px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin minitablet {
  @media (max-width: (800px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}