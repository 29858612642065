


.test{
  margin: 100px auto;
  display: flex;
  justify-content: center;
  max-width: 60vw;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  span{
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  &_time{
    min-height: 265px;
    max-width: 220px;
    display: grid;
    grid-template-rows: 160px 95px;
    gap: 9px;
    &_count{
      background: #FFFFFF;
      border-radius: 22px;
      padding: 16px 30px;
      .progress_bar{
        margin-top: 12px;
      }
    }
  }
  &_content{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    .headerContent{
  display: flex;
  justify-content:space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #858585;
  padding: 8px 5px;
      .arrowBtn{
        &:hover{
          span{
            cursor: pointer;
            color: #2456C8;
          }
          cursor: pointer;
          color: #2456C8;
        }
      }
}

    .headerContentDirect{
      display: flex;
      justify-content:space-between;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #858585;
      padding: 8px 5px;
      .arrowBtn{
        &:hover{
          span{
            cursor: pointer;
            color: #2456C8;
          }
          cursor: pointer;
          color: #2456C8;
        }
      }
    }
    .question{
      background: #FFFFFF;
      border-radius: 10px;
      max-width: 796px;
      min-height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 48px;
      >img{
        max-height: 500px;
        max-width: 600px;
      }
      p{
        align-items: center;
        text-align: left;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 0;
        white-space: pre-line;

      }
    }

  }

}

.popover{


}