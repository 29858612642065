.not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 113px;
  height: 100vh;
  .info{
    >.info-not-found{
      font-weight: 600 !important;
      font-size: 46px !important;
      line-height: 56px !important;
      color: #000000 !important;
      width: 500px;
      margin-bottom: 44px;
    }
    >button{
      padding: 12px 16px !important;
      border-radius: 12px;
      cursor: pointer;
      border: none;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

}