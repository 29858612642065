
.start_menu{
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  max-width: 593px;
  min-width: 560px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 36px;
  margin: 0 auto;
p{
  margin-bottom: 0;
  text-align: left !important;

}
  h2{
    margin-bottom: 20px !important;
  }
  .iconWrapper{

    display: flex;
    justify-content: center;
    gap: 28px;
    margin-top: 28px;
    margin-bottom: 20px;
  }
  .iconWrapperNull{

    margin-top: 28px;

  }



>img{
  //padding:10px 0px;
}
  &>p{
    align-items: center;
    text-align: center;
    white-space: pre-line;

  }
  p{
    font-weight: 400;
    display: flex;
    gap: 10px;
    font-size: 16px;
    line-height: 19px;
    color: #141313;
  }
}



