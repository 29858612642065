
@import "../../../scss/media";
@keyframes animateClose {
  from {

    transform: rotateY(180deg);
  }

  to {

    transform: rotateY(0deg);
  }
}

@keyframes animate {
  0% {
   opacity: 0;
    transform:  translateX(-30px)
  }
  100% {
    opacity: 1;
    transform:  translateX(0px)
  }
}

@keyframes animateTrans {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);

  }

}

.root{
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../../../utils/img/bg-main.svg");
  background-repeat: no-repeat;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-position:center;
  background-size: 107% 110%;
  @include middledeskM {
    background-size: 100% 107%;
    background-position:right  top;
    background-image: url("../../../utils/img/bg-main-mini.svg");

  }
}


.main{
  margin: 0 auto;
  min-width: 1173px;
  min-height: 600px;
  padding: 32px 80px;
  border-radius: 60px;
  background: white;
  //background: linear-gradient(106.51deg, rgba(255, 255, 255, 0.3) 8.72%, rgba(217, 217, 217, 0) 123.61%, rgba(255, 255, 255, 0) 123.61%);
  //filter: drop-shadow(0px 3px 22px rgba(11, 31, 77, 0.1));
  backdrop-filter: blur(0px);
  border: 1px solid transparent;
  //border-image: linear-gradient(rgba(255, 255, 255, 1),rgba(233, 233, 233, 1));
  box-shadow: 2.80577px 60px 94px rgba(3, 17, 51, 0.0353333), 1.08074px 26.9413px 29.9407px rgba(3, 17, 51, 0.0546667);
  overflow: hidden;
  animation: animateTrans 1s ease ;
@include minidesk{
  box-shadow: whitesmoke;
  width: 100vw;
  height: 100vh;
  border-radius: 0px;
}
  .text_wrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    color: #1d39c4;
    max-width: 400px;
    .text{
      margin-bottom: 44px;
      h1{
        //animation: animate 1s ease;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 52px !important;
        line-height: 63px !important;
      }
      h2{
        //animation: animate 1s ease;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 28px !important;
        line-height: 34px !important;
      }
     svg{
       animation: animate 1s ease;
     }
    }
  }
}
.main_link{

  position: absolute;
  display: flex;
  justify-content: center;

  width: 85%;
  opacity: 0;
  z-index: -1;
  align-items: center;
  h2{

    margin-bottom: 24px !important;
  }

}

.main_info{
  opacity: 1;
  z-index: 1;
}
.page_link{
  min-width: 550px !important;
  width: 550px !important;
  min-height: 300px;
  height: 300px;

  padding: 60px 44px;
  border-radius: 28px;
  .main_link{
    opacity: 1;
    z-index: 1;
  }
  .main_info{

    opacity: 0;
    z-index: -1;
  }
}
.link_wrapper{
  max-width: 450px;

  margin-top: 20px;
  h2{
    margin-bottom: 4px;
  }

}
.close{
  position: absolute;
  top:-56px;
  right:-20px;
  cursor: pointer;
}
.Input {

  height: 40px;
  margin: 0;
  border-radius: 10px;
  align-items: center;;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  .ant-form-item-explain {
    margin-left: 10px;
  }
}