
#root{
  font-family: 'Inter Semi Bold',sans-serif !important;
}

.Header{
  background: #FFFFFF !important;
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
  padding: 0px !important;
}
.logo{
  max-width: 232px !important;
  padding: 14px 0px 14px  62px ;
  min-width: 232px !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $prim-8;
span{
color:$black;
}

}
.user{
  margin-left: calc(80vw - 40px) !important;
  p{
    padding-top: 15px;
  }
  display: flex;
  align-items: center;
  gap: 16px;
}