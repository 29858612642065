
@keyframes animateTrans {
	0% {
		transform: translate3d(40px, 0, 0);
		opacity: 0;


	}

	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;

	}

}

.animateTrans{
	animation: animateTrans .5s ;
	animation-timing-function: cubic-bezier(0.3,0.3,1,1) ;
}
.header-test {
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	//width: 100vw;
	min-width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background: #ffffff;
	.header-wrapper {
		display: flex;
		padding: 16px 120px;
		justify-content: space-between;
	}

	.info-time {
		display: flex;
		gap: 20px;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: $TextPrimary;
		align-items: center;
		.hover-quest {
			display: flex;
			align-items: center;
			cursor: pointer;
			&:hover {
				color: $blue-7;
				svg {
					path {
						fill: $blue-7;
					}
				}
			}
		}
		> div {
			display: flex;
			gap: 8px;
			align-items: center;
		}
		.icon-list {
			align-items: end;
		}
	}
}
.time-danger {
	svg {
		//stroke: $TextDanger;

		path {
			fill: $TextDanger;
		}
	}
}
.header-count-Question {
	overflow: hidden;
	overflow-y: scroll;
	box-shadow: $shadow30;
	border-radius: 10px;
	background: $white;
}
.ant-popover-inner-content {
	padding: 0 !important;
}
.scroll {
	height: 20vh;
	.list-item-answered {
		display: flex;
		justify-content: space-between;
		align-items: center;
		svg {
			path {
				fill: $TextSuccess;
			}
		}
	}
	.list-item-active {
		background: $prim-1;
		color: $prim-8;
		svg {
			path {
				fill: $prim-8;
			}
		}
	}
	.list-item {
		width: 120px;
		height: 32px;
		padding: 8px 16px;
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 16px !important;
		&:hover {
			background: $prim-1;
			cursor: pointer;
			color: $prim-9;
		}
	}
	.simplebar-content-wrapper {
		padding: 0;
	}
	&:before {
		border-radius: 0px !important;
	}
	.simplebar-scrollbar:before {
		position: absolute;
		content: '';
		background: $divider !important;
		border-radius: 7px;
		left: 2px;
		right: 2px;
		opacity: 0;
		transition: opacity 0.2s linear;
	}
	.simplebar-track {
		z-index: 1;
		position: absolute;
		right: 0;
		bottom: 0;
		pointer-events: none;
		overflow: hidden;
	}
	> div {
		padding: 0 !important;
	}
}
.simplebar-placeholder {
	height: 0px !important;
}
