h1{
  //background: #8d8686;
  font-size: 40px !important;
}
h1,h2,h3,h4,h5{
  margin-bottom: 0 !important;
}
h2{
  font-weight: 500 !important;
  font-size: 26px !important;
  line-height: 31px !important;
  color:$TextTitle !important;
}
h3{
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 30px !important;
  color: $TextTitle;
}

h4{
  font-weight: 500 !important;
  font-size: 16px !important ;
  line-height: 19px !important;
  color:$TextSecondary !important;
}

p{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

}
.text-description{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $TextSecondary !important;
}

.login-title{
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  color: $black;
}

.test-header-title{
  span{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
   color:  $TextTitle;
  }
a{
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $TextTitle;
  display: flex;
  align-items: center;
  &:hover{
    color: $blue-7;
  }
}

}

.text-character{
 color:$character-primary !important;
}

.text-center{
  text-align: center;
}

.h1-bold {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 44px !important;
}
.h1-regular {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 36px !important;
  line-height: 44px !important;
}

.h2-semi-bold {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  white-space: pre-line !important;
}
.h2-medium {
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 29px !important;
}

.h3-medium {
  font-weight: 500 !important;
  font-size: 20px !important;

}
.h3-semi-bold {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

.h4-bold {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
}
.h4-medium {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
}


.h4-regular {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px !important;
}

.h5-medium {
  font-weight: 500 !important;
  font-size: 16px !important;

}

.h5-regular {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.body-medium {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
.body-regular {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
.body-medium-width {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.footnote-medium {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
}
.footnote-regular {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
}


.text-success {
  color: $TextSuccess;
}
.text-ch-secondary {
  color: $character-secondary !important;
}
.text-ch-primary {
  color: $character-primary !important;
}
.text-ch-placeholder {
  color: $character-placeholder !important;
}
.text-prim{
  //cursor: pointer;
  color: $prim-8 !important;
  &:hover{
    //color: $prim-7 !important;
  }
}


