@import '../variables';


.btn-primary{
  width: 100% !important;
  padding: 12px 96px !important;
  height: inherit !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  background: $prim-9 !important;
  border-radius: 12px !important;
 border:1px solid transparent !important;
  margin-bottom: 0px !important;
  span{
    color: $white !important;
  }
  &:hover{
    background: $prim-8 !important;
  }
}

.btn-default{
  background: $prim-9;
  padding: 12px 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  color: $white;
  &:hover{
    background: $prim-8;
  }
  &:active{
    background: $prim-7;
  }
}

.btn-stroke{
  background: none ;
  padding: 12px 16px;
  border-radius: 12px;
  border:  1px solid $prim-9;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  color: $prim-9;
  &:hover{
    background: $blue-1;
  }
  &:active{
    background: $blue-2;
  }
}

.btn-
.btn-decorative {
  background: none ;
  padding: 36px 60px;
  border-radius: 10px;
  border: 2px solid $white;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  box-shadow: $shadow10;
  color: $prim-9;
  &:hover{
    border: 2px solid $blue-7;
    color: $blue-7;
  }
  &:active{
    background: $blue-2;
  }
}


.btn{
  cursor: pointer;
span{
  color: #FFFFFF !important;
}
}

.btn-login{
  background: $prim-9 !important;
  padding: 12px 124px !important;
  border-radius: 12px !important;
  font-weight: 700 !important;
  height: inherit !important;
  font-size: 18px !important;
  line-height: 22px !important;
  border: none !important;

}

.btn-start{
  padding: 14px 190px;
  border-radius:14px ;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: none;

}
.ant-btn:focus{
  background: #8d8686;
}
.active-btn-test{
span{
  color: $blue-7;
}
border: 2px solid $blue-7 !important;
  background:$blue-1 !important;

}

.btn_wrapper{
  max-width: 796px;
  display: flex;
  justify-content: space-between;

  //display: grid;
  //grid-template-columns:repeat(3,1fr);
  gap: 8px;
  .active-btn-test{

  }
  .icon-btn{
    display:none !important;
  }
  button{
    flex: 1 1 ;
    padding:40px 15px;
    border-radius: 10px;
    height: inherit;
    min-width: 260px;
    align-items: center;
    text-align: center;
    span{
      margin: 0 auto;
      height: inherit;
      align-items: center;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      white-space:pre-line;

      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: $TextPrimary !important;
    }
  }
}


.btn_wrapper-inline{
  max-width: 796px;
  display: grid;
  grid-template-columns:repeat(1,796px);
  gap: 8px;

  button{

    padding:20px 60px 20px 20px;//
    border-radius: 10px;
    height:auto;
    width: inherit;
    align-items: center;
    text-align: left;
    span{
      margin-right: 20px;
color: $character-primary !important;
      height: inherit;
      align-items: center;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      white-space: pre-line;
    }
  }
}

.icon-btn{
  display:inline !important;
  height: inherit;
  font-weight: 400 ;
  font-size: 16px;
  line-height: 19px;

  border-radius: 10px;

}

.radio-btn{
  .ant-radio{
    .ant-radio-inner{


    }
  }
.ant-radio-checked{
  .ant-radio-inner{

    background: $blue-6 !important;
    border-color:$blue-6 !important;
    &:hover{
      background: $blue-7 !important;
      border-color:$blue-7 !important;
    }
    &:active{
      background: $blue-8 !important;
      border-color:$blue-8 !important;
    }
    &:after{

      background: #FFFFFF ;
      transform: scale(0.5);
    }
  }
}
}


.checkbox-btn{
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  align-items: self-start !important;
  display: flex;
  justify-content: center;
  top: 10px;
  a{
    color: $blue-7;
  }

  .ant-checkbox-checked{
    .ant-checkbox-inner{
      height: 20px;
      width: 20px;
      border-radius: 5px !important;
      background: $blue-6;
      &:after{
        height: 14px;
        width: 7px;
        align-items: center;
      }
    }
  }

  .ant-checkbox{
    height: 25px;
    width:25px;
    border-radius: 5px;
.ant-checkbox-input{
  height: 25px;
  width: 25px;
  border-radius: 5px;
}
    .ant-checkbox-inner{
      height: 25px;
      width: 25px;
      border-radius: 5px !important;
      &:after{
        height: 14px;
        width: 7px;
        align-items: center;
      }
    }
  }
}




.btn-secondary{
  padding: 12px 96px !important;
  height: inherit !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  background: $white !important;
  border-radius: 12px !important;
  span{
    color: $prim-9 !important;
  }

}
.ant-btn-primary{
  width: auto;
  height: inherit !important;
  background: $prim-9 !important;
  padding: 12px 20px !important;
  box-shadow: 0px 3px 12px rgba(3, 17, 51, 0.05);
  border-radius: 12px !important;
  span{
    color:$white;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
  }
}

.ant-btn-default{
  width: auto;
  height: inherit !important;
  padding: 12px 20px !important;
  border-radius: 10px !important;
  span{
    color:$prim-9 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
  }
}